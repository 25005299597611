/* RSC */

import { css } from '@bts-web/utils-style-engine';
import { PropsWithChildren, ReactElement } from 'react';

interface ErrorPageProps extends PropsWithChildren {
  title: string | ReactElement;
  description: string | ReactElement;
}

const ErrorPage = ({ title, description }: ErrorPageProps) => (
  <div
    data-testid="error-page"
    className={css({
      display: 'flex',
      alignItems: 'center',
      minHeight: '100vh',
      justifyContent: 'center',
      gap: 0,
    })}
  >
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        padding: 'extra_large_2',
        gap: 'extra_small_2',
        textAlign: 'center',
        margin: 'auto',
      })}
    >
      <p
        className={css({
          color: 'neutrals.text_primary',
          fontSize: 'headline.small_medium',
          fontWeight: 'headline.small_medium',
          lineHeight: 'headline.small_medium',
        })}
      >
        {title}
      </p>
      <p
        className={css({
          color: 'neutrals.text_secondary',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          lineHeight: 'body.medium',
        })}
      >
        {description}
      </p>
    </div>
  </div>
);

export { ErrorPage };
